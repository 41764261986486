import React, { useState, useEffect } from 'react';
import { areaFields }  from "./predefinedGames";
import { Button, Dropdown, Icon } from 'semantic-ui-react';

const Game = () => {
    const GAME_INITIAL = 0;
    const GAME_STARTED = 1;
    const GAME_PAUSED = 2;
    const GAME_FINISHED = 3;

    const [startTime, setStartTime] = useState(0);
    const [gameState, setGameState] = useState(GAME_INITIAL);
    const [difficulty, setDifficulty] = useState('easy');
    const [focusField, setFocusField] = useState(null);
    const [history, setHistory] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [fields, setFields] = useState([]);
    const [fullscreen, setFullscreen] = useState(false);
    const size = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    useEffect(() => {
        let interval = null;
        if (gameState === GAME_STARTED) {
            interval = setInterval(() => {
                setStartTime(startTime => startTime + 1);
            }, 1000);
        } else if (gameState === GAME_INITIAL) { // build the board initially with empty cells
            buildBoard([]);
        } else if (gameState !== GAME_STARTED && startTime !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [gameState, startTime]);

    /**
     * initiate Fields from an external API
     */
    const initFields = () => {
        fetch('https://sugoku.herokuapp.com/board?difficulty=' + difficulty)
            .then(response => response.json())
            .then(data => {
                const board = data.board;
                buildBoard(board);
            });
    };

    /**
     * Start a new game
     */
    const startGame = () => {
        initFields();
        setStartTime(0);
        setGameState(GAME_STARTED);
    };

    const endGame = () => {
        setGameState(GAME_FINISHED);
    };

    /**
     * Toggle gameState
     */
    const togglePause = () => {
        if (gameState === GAME_PAUSED) {
            setGameState(GAME_STARTED);
        } else if (gameState === GAME_STARTED) {
            setGameState(GAME_PAUSED);
        }
    };


    const changeField = (field) => {
        const newFields = [...fields];
        newFields[field.id] = field;
        setFields(newFields);
    };

    /**
     * Removes the focus from fields
     * needed to highlight numbers from numpad
     */
    const unFocus = () => {
        removeHighlight();
        setFocusField(null);
    };

    /**
     * Builds a board from the predefinedArray and set fields
     * PredefinedArray must be 9x9 multidimensional Array
     *
     * @param predefinedArray
     */
    const buildBoard = (predefinedArray) => {
        const fields = [];
        let id = 0;
        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 9; j++) {
                // for initial empty array, we use 0
                let predefinedValue = predefinedArray.length > 0 ? parseInt(predefinedArray[i][j]) : 0;
                fields.push({
                    id: id,
                    row: i,
                    column: j,
                    value: predefinedValue,
                    display: false,
                    highlight: false,
                    underline: false,
                    locked: predefinedValue > 0,
                    error: false,
                    focus: false,
                    mark: [],
                });

                id++;
            }
        }

        setFields(fields);
    };

    /**
     *
     * @param event KeyboardEvent
     * @param field <Field>
     */
    const keyDown = (event, field) => {
        // keyCodes for numbers + numpad
        if (!field.locked && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
            history.push({
                field: field,
                value: field.value,
            });
            setHistory(history);
            let number = parseInt(event.key);
            if (editMode) {
                markField(number)
            } else {
                writeField(number);
            }
            highlightField(field);
            validateField(field);
        }
    };

    const markField = (number) => {
        if (focusField.mark.includes(number)) {
            focusField.mark = focusField.mark.filter(function (marker) {
                return marker !== number;
            })
        } else {
            focusField.mark.push(number);
        }
        changeField(focusField);
    };

    const writeField = (number) => {
        focusField.value = number;
        changeField(focusField);
    };

    const toggleField = (currentField) => {
        if (currentField === focusField) {
            unFocus();
        } else {
            highlightField(currentField);
        }
    };

    /**
     * @param currentField
     */
    const highlightField = (currentField) => {
        highlightNumber(currentField.value);
        setFocusField(currentField);
        fields.map((field) => {
            field.underline = field.row === currentField.row || field.column === currentField.column || areaFields(currentField).includes(field.id);
            field.focus = field.id === currentField.id;
            changeField(field);

            return field;
        });
    };

    /**
     * Devices without a keyboard need to click the numpad to insert numbers
     *
     * @param number
     */
    const clickNumpad = (number) => {
        if (focusField !== null && !focusField.locked) {
            history.push({
                field: focusField,
                value: focusField.value,
            });
            setHistory(history);

            if (editMode) {
                markField(number)
            } else {
                writeField(number);
            }
            highlightField(focusField);
            validateField(focusField);
        }
        highlightNumber(number);
    };

    const toggleMode = () => {
        setEditMode(!editMode);
    };

    const toogleFullscreen = () => {
        setFullscreen(!fullscreen);
    };

    /**
     * Reverts the last change
     */
    const undo = () => {
        if (history.length > 0) {
            let lastElement = history.pop();
            let lastField = lastElement.field;
            lastField.value = lastElement.value;
            changeField(lastField);
            setHistory(history);
            removeHighlight();
        } else {
            removeHighlight();
        }
    };

    /**
     * @param number
     */
    const highlightNumber = (number) => {
        if (number === 0) {
            removeHighlight();
        } else {
            fields.map((field) => {
                field.highlight = field.value === number || field.mark.includes(number);
                changeField(field);

                return field;
            })
        }
    };

    /**
     * Removes highlight, focus and underline from all fields
     */
    const removeHighlight = () => {
        fields.map((field) => {
            field.highlight = false;
            field.focus = false;
            field.underline = false;
            field.error = false;
            changeField(field);

            return field;
        })
    };

    /**
     * Validate the given field for errors
     *
     * @param currentField
     */
    const validateField = (currentField) => {
        let overlaps = fields.filter((field) => {
            // same value and in same row, column, or area
            return currentField.value === field.value
                && currentField.value !== 0
                && currentField.id !== field.id
                && (
                    currentField.row === field.row
                    || currentField.column === field.column
                    || areaFields(currentField).includes(field.id)
                );
        });

        if (overlaps.length > 0) { // if something is found we set all fields to error
            currentField.error = true;
            overlaps.map((field) => {
                field.error = true;
                changeField(field);

                return field;
            });
            changeField(currentField);
        } else {
            fields.map((field) => {
                let changeThisField = false;
                // remove errors from fields
                if (field.error) {
                    field.error = false;
                    changeThisField = true;
                }

                // remove markers from fields in line
                if (currentField.value !== 0
                    && field.mark.includes(currentField.value)
                    && currentField.id !== field.id
                    && (
                        currentField.row === field.row
                        || currentField.column === field.column
                        || areaFields(currentField).includes(field.id)
                    )) {
                    field.mark = focusField.mark.filter(function (marker) {
                        return marker !== currentField.value;
                    });
                    changeThisField = true;
                }

                if (changeThisField) { //only save the field when we changed something to reduce calls
                    changeField(field);
                }

                return field;
            });

             let emptyFields = fields.filter((field) => {
                 return field.value === 0;
             });

            if (emptyFields === 0) {
                endGame();
            }
        }
    };

    /**
     * Returns the timer formatted as mm:ss
     *
     * @returns {string}
     */
    const formattedTime = () => {
        let minutes = Math.floor(startTime / 60).toString().padStart(2, '0');
        let seconds = (startTime % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    /**
     * Counts how often the given number is missing
     *
     * @param {number} number
     * @returns {number}
     */
    const getCountForNumber = (number) => {
        let numberFields = fields.filter((field) => {
            return field.value === number;
        });

        return size.length - numberFields.length;
    };

    /**
     * @param event
     * @param data
     */
    const onChangeDifficulty = (event, data) => {
        setDifficulty(data.value);
    };

    return (
        <div className={`ui container sudoku  ${fullscreen ? 'full-screen': ''}`}>
            <div className={'settings'}>
                {!fullscreen &&
                    <Dropdown
                        placeholder={'Difficulty'}
                        selection
                        defaultValue={difficulty}
                        fluid
                        options={[
                            {text: 'Einfach', value: 'easy'},
                            {text: 'Mittel', value: 'medium'},
                            {text: 'Schwer', value: 'hard'},
                            ]}
                        onChange={onChangeDifficulty}
                    />
                }
                <div className={'timer'}>{formattedTime()}</div>
                <div className={'button-group'}>
                    <Button onClick={() => startGame()}> Start Game</Button>
                    <Button onClick={() => togglePause()} className={`${gameState === GAME_PAUSED ? 'blue' : ''}`}><Icon name={`${gameState === GAME_PAUSED ? 'play' : 'pause'}`} /></Button>
                    <Button onClick={() => toogleFullscreen()}><Icon name={'expand arrows alternate'} /></Button>
                    <Button onClick={() => toggleMode()} className={`${editMode ? 'blue' : ''}`}><Icon name={'edit'} /></Button>
                    <Button onClick={() => clickNumpad(0)}><Icon name={'eraser'} /></Button>
                    <Button onClick={() => undo()}><Icon name={'undo'} /></Button>
                </div>
            </div>
            <div className={`game-field ${gameState === GAME_PAUSED ? 'paused' : ''}`}>
                {fields.map((field, index) =>
                        <div
                            className={`field ${field.locked ? 'locked' : ''} ${field.highlight ? 'highlight' : ''} ${field.underline ? 'underline' : ''} ${field.error ? 'error' : ''} ${field.focus ? 'focus' : ''}`}
                            data-id={field.id}
                            key={index}
                            onClick={() => toggleField(field)}
                            onKeyDown={(e) => keyDown(e, field)}
                            tabIndex="0"
                        >
                            {field.value > 0
                                ? <span className={'field-value'}>{field.value > 0 ? field.value : ''}</span>
                                : field.mark.length > 0
                                    ? <div className={'marker-wrapper'}>{size.map((number, innerIndex) =>
                                        <div
                                            key={`${index} ${innerIndex}`}
                                            className={`marker ${field.mark.includes(number) ? 'marked' : ''}`}
                                        >
                                            {number}
                                        </div>
                                    )}</div>
                                    : ''
                            }
                    </div>
                )}
                {gameState === GAME_FINISHED ?
                    <div className={'winning-screen'}>
                    <h2>You've won.</h2>
                    <div>Your time was {formattedTime()}</div>
                    <div>Try your skill on a higher difficulty or start a new game.</div>
                </div> : ''}
            </div>
            <div className={'number-controls'}>
                {size.map((number) => <div
                        className={`number ${getCountForNumber(number) === 0 ? 'finished' : ''}`}
                        key={number}
                        onClick={() => clickNumpad(number)}
                    >{number}
                    <span className={'counter'}>{getCountForNumber(number)}</span>
                </div>)}
            </div>
            <div className={`${gameState !== GAME_FINISHED ? 'hidden' : ''}`}>You won</div>
        </div>
    )
};

export default Game;