import React from 'react';
import Game from "./components/game";
import 'semantic-ui-css/semantic.min.css';
import './styles/sudoku.scss';

function App() {
  return (
    <div className="App">
        <Game />
    </div>
  );
}

export default App;
